import Image from "next/image";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import { AnimatePresence, motion } from "framer-motion";
import { RiMenu3Fill } from "react-icons/ri";

const ServicesMegaMenu = dynamic(() => import("./ServicesMegaMenu"));
// const ProjectsMegaMenu = dynamic(() => import("./ProjectsMegaMenu"));
const AboutMenu = dynamic(() => import("./AboutMenu"));
// const ServicesMobileMegaMenu = dynamic(() =>
//   import("./ServicesMobileMegaMenu")
// );
// const ProjectsMegaMobileMenu = dynamic(() =>
//   import("./ProjectsMegaMobileMenu")
// );
const AboutMobileMenu = dynamic(() => import("./AboutMobileMenu"));

const Header = () => {
  const [menu, setMenu] = useState(false);
  const [megaMenu, setMegaMenu] = useState(false);
  // const [projectsMegaMenu, setProjectsMegaMenu] = useState(false);
  const [serviceMobileMenu, setServiceMobileMenu] = useState(false);
  const [aboutMobileMenu, setAboutMobileMenu] = useState(false);
  // const [projectsMobileMenu, setProjectsMobileMenu] = useState(false);
  const [aboutMenu, setAboutMenu] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenu(false);
        setMegaMenu(false);
        // setProjectsMegaMenu(false);
        // setProjectsMobileMenu(false);
        setServiceMobileMenu(false);
        setAboutMenu(false);
        setAboutMobileMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = menu ? "hidden" : "visible";
  }, [menu]);

  // const variant = {
  //   initial={{ x: -20, opacity: 0 }}
  //   animate={{ x: 0, opacity: 1 }}
  //   exit={{ x: -20, opacity: 0 }}
  // }

  const variants = {
    hidden: {
      height: 0,
    },
    visible: {
      height: "auto",

      transition: { staggerChildren: 0.5 },
    },

    exit: {
      height: 0,

      transition: {
        duration: 0.3,
      },
    },
  };

  const childVariants = {
    hidden: {
      y: 40,
      opacity: 0,
    },

    visible: {
      y: 0,
      opacity: 1,
    },

    exit: {
      opacity: 0,
    },
  };

  return (
    <header
      className="flex items-center relative justify-between px-5 xl:px-0  pt-4 z-50 md:max-w-screen-xl mx-auto  backdrop-filter backdrop-blur-md duration-500 ease-in pb-3"
      style={{ backdropFilter: "blur(25px)" }}
      // className={`backdrop-filter backdrop-blur-md h-[auto] min-h-[80px] duration-500 ease-in z-[777] fixed w-full `}
    >
      <Link href={"/"} className="flex items-center">
        <Image
          src={"/images/logo-new-update.webp"}
          width={150}
          height={40}
          loading="lazy"
          className="xl:w-[150px] xl:h-[40px]"
          alt="zysoftec logo"
        />
      </Link>
      <div className="items-center hidden xl:flex space-x-9">
        <Link
          href={"/"}
          className="flex items-center"
          onClick={() => setMenu(false)}
        >
          <span className="text-base font-bold">Home</span>
        </Link>
        {/* <Link href={"/blogs"} className="flex items-center">
          <span className="text-base font-normal">Blog</span>
        </Link> */}
        <div
          onMouseEnter={() => {
            // setProjectsMegaMenu(false);
            setAboutMenu(false);
            setMegaMenu(true);
          }}
          onMouseLeave={() => setMegaMenu(false)}
          className="flex   relative items-center space-x-2  "
        >
          <span className="text-base font-bold cursor-pointer relative z-10 ">
            Services
          </span>
          <Image
            src={"/images/dropdown.svg"}
            width={8}
            height={5}
            alt="alt text"
          />
          <AnimatePresence>
            {megaMenu && <ServicesMegaMenu setMenu={setMenu} />}
          </AnimatePresence>
        </div>
        {/* <div
          onMouseEnter={() => {
            setMegaMenu(false);
            setAboutMenu(false);
            setProjectsMegaMenu(true);
          }}
          // onMouseLeave={() => setProjectsMegaMenu(false)}
          className="flex items-center space-x-2 cursor-pointer"
        >
          <a href="/case-study" className="text-base font-normal">Portfolio</a>
          {/* <Image
            src={"/images/dropdown.svg"}
            width={8}
            height={5}
            alt="alt text"
          />
          {projectsMegaMenu && <ProjectsMegaMenu />}
        </div> */}
        <Link
          href={"/case-study"}
          className="flex items-center"
          onClick={() => setMenu(false)}
        >
          <span className="text-base font-bold">Portfolio</span>
        </Link>
        <Link
          href={"/reviews"}
          className="flex items-center"
          onClick={() => setMenu(false)}
        >
          <span className="text-base font-bold">Reviews</span>
        </Link>
        <div
          onMouseEnter={() => {
            setMegaMenu(false);
            // setProjectsMegaMenu(false);
            setAboutMenu(true);
          }}
          onMouseLeave={() => setAboutMenu(false)}
          className="relative flex items-center space-x-2 cursor-pointer"
        >
          <span className="text-base font-bold">About us</span>
          <Image
            src={"/images/dropdown.svg"}
            width={8}
            height={5}
            alt="alt text"
          />
          <AnimatePresence>
            {aboutMenu && <AboutMenu setMenu={setMenu} />}
          </AnimatePresence>
        </div>
        {/* <Link href={"/get-quote"} className="flex items-center">
          <span className="text-base font-normal">Get a quote</span>
        </Link> */}
        {/* <Link href={"/contact-us"} className="flex items-center">
          <span className="text-base font-normal">Contact us</span>
        </Link> */}
        <Link
          href={"/get-quote"}
          className="px-5 py-3 flex items-center justify-between space-x-2 rounded-[5px] border-2 border-solid border-[#16349F]"
          onClick={() => setMenu(false)}
        >
          {/* <Image src={"/images/today-32.png"} width={18} height={18} /> */}
          <span className="text-base text-[#16349F] font-bold">
            GET A QUOTE
          </span>
        </Link>
        {/* <Link
          href={"/request-member"}
          style={{
            background:
              "linear-gradient(280.91deg, #1A3183 28.64%, #16349F 77.71%)",
          }}
          className="px-5 py-3 flex items-center justify-between space-x-2 rounded-[5px]"
        >
         
          <span className="text-base font-normal text-white">
            Request a Member
          </span>
        </Link>  */}
      </div>
      <div className="flex items-center justify-center xl:hidden">
        {/* Hamburger Icon */}
        <div className="block cursor-pointer hamburger xl:hidden">
          {/* <Image
            src={"/images/menu-icon.svg"}
            width={30}
            height={30}
            onClick={() => setMenu(true)}
            alt="alt text"
          /> */}
          <RiMenu3Fill color="#1E3E92" style={{fontSize: "25px", fontWeight: '600'}} onClick={() => setMenu(true)} />
        </div>
        {/* {menu && (
          <div className="right-0 float-right cursor-pointer hamburger xl:hidden">
            <Image
              src={"/images/close-menu-icon.svg"}
              width={30}
              height={30}
              onClick={() => setMenu(false)}
              alt="alt text"
            />
          </div>
        )} */}
      </div>

      {/* Mobile Menu  */}
      <AnimatePresence>
        {menu && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-[999] flex items-start justify-start w-full h-screen bg-[white] xl:hidden"
            onClick={() => setMenu(false)}
            style={{ backdropFilter: "blur(1px)" }}
          >
            <motion.div
              key={"drawer"}
              initial={{ x: "-100%" }}
              animate={{ x: 0 }}
              exit={{ x: "-100%" }}
              // transition={{ delay: 0.1, duration: 0.3 }}
              className={`text-[16px]  py-5 border-[#D1D1D1]  overflow-auto  px-5   drawer-scrollbar  h-screen w-full backdrop-filter backdrop-blur-md duration-500 ease-in`}
              style={{ backdropFilter: "blur(70px)"}}
               onClick={(e) => e.stopPropagation()}
            >
              <div className="flex items-center justify-between">
                <Link href={"/"} className="flex items-center">
                  <Image
                    src={"/images/logo-new-update.webp"}
                    width={130}
                    height={40}
                    className="xl:w-[150px] xl:h-[40px]"
                    alt="zysoftec logo"
                  />
                </Link>
                {menu && (
                  <div className="right-0 float-right cursor-pointer hamburger xl:hidden">
                    <Image
                      src={"/images/close-menu-icon.svg"}
                      width={20}
                      height={20}
                      onClick={() => setMenu(false)}
                      alt="alt text"
                    />
                  </div>
                )}
              </div>
              {/* <Link
            href={"/get-quote"}
            className="px-3 py-2 flex items-center justify-between space-x-2 rounded-[5px] border-2 w-fit border-solid border-[#16349F] "
          >
            <span className="text-base font-normal text-black">
              Get a Quote
            </span>
          </Link> */}
              <div className="flex flex-col mt-8 space-y-8 " >
                <Link
                  href={"/"}
                  className="flex items-center"
                  onClick={() => setMenu(false)}
                >
                  <span className="text-base font-normal">Home</span>
                </Link>
                <div
                  onClick={() => {
                    // setProjectsMobileMenu(false);
                    setAboutMobileMenu(false);
                    setServiceMobileMenu(!serviceMobileMenu);
                  }}
                  className="flex flex-col space-y-2 cursor-pointer"
                >
                  <div className="flex space-x-2">
                    <span className="text-base font-normal">Services</span>
                    <Image
                      src={"/images/dropdown.svg"}
                      width={8}
                      height={5}
                      alt="alt text"
                    />
                  </div>
                  <AnimatePresence>
                    {
                      serviceMobileMenu && (
                        <motion.div
                          variants={variants}
                          initial="hidden"
                          animate="visible"
                          exit={"exit"}
                          // initial={{ y: 20, opacity: 0 }}
                          // animate={{ y: 0, opacity: 1 }}
                          // exit={{ y: 20, opacity: 0 }}
                          // transition={{ delay: 0.1, duration: 0.3 }}
                          className="space-y-5 "
                        >
                          <motion.div
                            variants={childVariants}
                            className="flex flex-col space-y-2"
                          >
                            <p className="font-bold text-md fontMd text-primary">
                              Designing
                            </p>
                            {/* <div className="w-20 h-[3px] bg-primary"></div> */}
                            <ul className="flex flex-col space-y-2">
                              <li className="flex items-center space-x-2 w-fit">
                                <Link
                                  href="/services/UX/UI-Design-Solution"
                                  className="mega-menu-link"
                                  onClick={() => setMenu(false)}
                                >
                                  UI/UX Designs
                                </Link>
                              </li>
                              <li className="flex items-center space-x-2 w-fit">
                                <Link
                                  href="/services/social-media-design-services"
                                  className="mega-menu-link"
                                  onClick={() => setMenu(false)}
                                >
                                  Social Media Designs
                                </Link>
                              </li>
                              <li className="flex items-center space-x-2 w-fit">
                                <Link
                                  href="/services/graphic-design-services-in-usa"
                                  className="mega-menu-link"
                                  onClick={() => setMenu(false)}
                                >
                                  Graphics Designing
                                </Link>
                              </li>
                            </ul>
                          </motion.div>
                          <motion.div
                            variants={childVariants}
                            className="flex flex-col space-y-2"
                          >
                            <p className="font-bold text-md fontNew text-primary">
                              Web Development
                            </p>
                            {/* <div className="w-20 h-[3px] bg-primary"></div> */}
                            <ul className="flex flex-col space-y-2">
                              <li className="flex items-center space-x-2 w-fit ">
                                <Link
                                  href="/services/landing-page-design-agency"
                                  className="mega-menu-link"
                                  onClick={() => setMenu(false)}
                                >
                                  Landing Page Development
                                </Link>
                              </li>
                              <li className="flex items-center space-x-2 w-fit">
                                <Link
                                  href="/services/custom-web-development-services"
                                  className="mega-menu-link"
                                  onClick={() => setMenu(false)}
                                >
                                  Full Stack Websites
                                </Link>
                              </li>
                              <li className="flex items-center space-x-2 w-fit">
                                <Link
                                  href="/services/hire-frontend-developers"
                                  className="mega-menu-link"
                                  onClick={() => setMenu(false)}
                                >
                                  Front End Development
                                </Link>
                              </li>
                              <li className="flex items-center space-x-2 w-fit">
                                <Link
                                  href="/services/API-integration-services"
                                  className="mega-menu-link"
                                  onClick={() => setMenu(false)}
                                >
                                  API Integrations
                                </Link>
                              </li>
                              <li className="flex items-center space-x-2 w-fit">
                                <Link
                                  href="/services/admin-panel-development-services"
                                  className="mega-menu-link"
                                  onClick={() => setMenu(false)}
                                >
                                  Web Based Admin Panels
                                </Link>
                              </li>
                            </ul>
                          </motion.div>
                          <motion.div
                            variants={childVariants}
                            className="flex flex-col space-y-2 "
                          >
                            <p className="font-bold text-md fontNew text-primary">
                              App Development
                            </p>
                            {/* <div className="w-20 h-[3px] bg-primary"></div> */}
                            <ul className="flex flex-col space-y-2">
                              <li className="flex items-center space-x-2 w-fit">
                                <Link
                                  href="/services/Hire-android-developers"
                                  className="mega-menu-link"
                                  onClick={() => setMenu(false)}
                                >
                                  Android Development
                                </Link>
                              </li>
                              <li className="flex items-center space-x-2 w-fit">
                                <Link
                                  href="/services/Hire-ios-developers"
                                  className="mega-menu-link"
                                  onClick={() => setMenu(false)}
                                >
                                  IOS Development
                                </Link>
                              </li>
                            </ul>
                          </motion.div>
                  
                        </motion.div>
                      )
                      //  <ServicesMobileMegaMenu />
                    }
                  </AnimatePresence>
                </div>
                {/* <div
              onClick={() => {
                setServiceMobileMenu(false);
                setAboutMobileMenu(false);
                setProjectsMobileMenu(!projectsMobileMenu);
              }}
              className="flex flex-col space-y-2 cursor-pointer"
            >
              <div className="flex items-center space-x-2">
                <span className="text-base font-normal">Projects</span>
                <Image
                  src={"/images/dropdown.svg"}
                  width={8}
                  height={5}
                  alt="alt text"
                />
              </div>
              {projectsMobileMenu && <ProjectsMegaMobileMenu />}
            </div> */}
                <Link
                  href={"/case-study"}
                  className="flex items-center"
                  onClick={() => setMenu(false)}
                >
                  <span className="text-base font-normal">Portfolio</span>
                </Link>
                <Link href={"/reviews"} className="flex items-center">
                  <span
                    className="text-base font-normal"
                    onClick={() => setMenu(false)}
                  >
                    Reviews
                  </span>
                </Link>
                <div
                  onClick={() => {
                    setServiceMobileMenu(false);
                    // setProjectsMobileMenu(false);
                    setAboutMobileMenu(!aboutMobileMenu);
                  }}
                  className="flex flex-col space-y-2 cursor-pointer"
                >
                  <div className="flex space-x-2">
                    <span className="text-base font-normal">About us</span>
                    <Image
                      src={"/images/dropdown.svg"}
                      width={8}
                      height={5}
                      alt="alt text"
                    />
                  </div>
                  <AnimatePresence mode="wait">
                    {aboutMobileMenu && <AboutMobileMenu setMenu={setMenu} />}
                  </AnimatePresence>
                </div>
                <Link
                  href={"/get-quote"}
                  className="px-3 py-2 flex items-center justify-between space-x-2 rounded-[5px] border-2 w-fit border-solid border-[#16349F] "
                  onClick={() => setMenu(false)}
                >
                  <span className="text-base font-normal text-black">
                    Get a Quote
                  </span>
                </Link>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Header;
