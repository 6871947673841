import Header from "@/components/Header";
import "@/styles/globals.css";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";

function App({ Component, pageProps }) {
  const router = useRouter();
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="trustpilot-one-time-domain-verification-id"
          content="5208fcff-4d2a-4a68-b815-07af2499401c"
        />
        <Script
          strategy="afterInteractive"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              name: "Zysoftec",
              alternateName: "Zysoftec Software Solution",
              url: "https://zysoftec.com",
              logo: "https://zysoftec.com/_next/image?url=%2Fimages%2Flogo-new-update.webp&w=384&q=75",
              contactPoint: {
                "@type": "ContactPoint",
                telephone: "+92 3175682252",
                contactType: "customer service",
                contactOption: "TollFree",
                areaServed: ["US", "GB", "CA"],
                availableLanguage: "en",
              },
              sameAs: [
                "https://www.pinterest.com/ZysoftecSoftware/",
                "https://www.facebook.com/ZySoftec?mibextid=ZbWKwL",
                "https://zysoftec.com",
                "https://instagram.com/zysoftec?igshid=MzRlODBiNWFlZA==",
                "https://www.linkedin.com/in/zysoftec-online-software-development-agency-003b0a263",
              ],
            }),
          }}
        />
        {/* <Script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-GDX9PV03N7"
          strategy="afterInteractive"
        ></Script>
        <Script
          dangerouslySetInnerHTML={{
            __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      
        gtag('config', 'G-GDX9PV03N7');
        `,
          }}
          strategy="afterInteractive"
        /> */}
      </Head>
      {router.pathname !== "/" && <Header />}
      <Component {...pageProps} />
    </>
  );
}

export default App;
